import React from 'react'
import styled from 'styled-components'
import { darkGrey } from '../elements'

const PostWrapper = styled.section`
  margin: -4em auto auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 1em 1.5em 5em;
  flex-grow: 1;
  background: white;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  blockquote {
    color: ${darkGrey};
    padding: 0 0 0 32px;
    margin: 1.45rem 0 1.45rem 0;
  }
`

const PostContainer = props => {
  return <PostWrapper>{props.children}</PostWrapper>
}

export default PostContainer
