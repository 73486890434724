import React from 'react'
import styled from 'styled-components'

import { grey } from '../elements'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const Date = styled.p`
  margin: 2rem 2rem 0.5rem 2rem;
  color: ${grey};
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
`

const PostDate = props => {
  return (
    <Wrapper>
      <Date>{props.date}</Date>
    </Wrapper>
  )
}

export default PostDate
